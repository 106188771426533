.blogs-heading-home-page {
  color: #e87a28;
}

.blogs-images {
  width: 101%;
  margin-top: -60px;
  height: 400px;
  border-radius: 20px;
  cursor: pointer;
  object-fit: contain;
}

.blogs-main-div {
  background-color: rgba(174, 174, 174, 0.164);
}

.blog-image-container {
  position: relative;
  overflow: hidden;
}

.blog-title-container {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: left;
}

.blog-title {
  font-size: 1.2rem;
  margin: 0;
}

.read-more-button,
.upload-customer-button,
.save-customer-profile-button,
.edit-customer-profile-button {
  background: #e87a28;
  color: #fff;
  border: 1px solid #e87a28;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 1rem;
  border-radius: 5px;
}

.cancel-customer-profile-button {
  background: #fff;
  color: #e87a28;
  border: 1px solid #e87a28;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 1rem;
  border-radius: 5px;
}

.cancel-customer-profile-button:hover {
  background: rgba(128, 128, 128, 0.072);
  color: #e87b28b5;
}

.read-more-button:hover,
.upload-customer-button:hover,
.save-customer-profile-button:hover,
.edit-customer-profile-button:hover {
  background: #fff;
  color: #e87a28;
  border: 1px solid #e87a28;
}

.blog1-main-heading,
.blog2-main-heading,
.blog3-main-heading,
.blog4-main-heading {
  color: #e87a28;
  padding-bottom: 5px;
  display: inline-flex;
}

.blog-details-image {
  width: 100%;
}

.blog2-all-images {
  width: 100%;
  height: auto;
}

.blog1-diff-carrental-lease-heading,
.blog2-things-todo-inDubai-heading,
.blog2-things-todo-inDubai-heading-list > li,
.blog3-navigation-app-heading,
.blog4-navigation-app-heading {
  color: #e87a28;
}
.blog2-things-todo-inDubai-heading-list > li > p {
  color: black;
}

/* Free Consultation Form CSS  */

.free-consultation-main-div {
  padding: 30px 40px;
  background-color: #e87a28;
}

.free-consultation-text {
  border-radius: 20px;
  background-color: white;
  padding: 20px 40px 30px 40px;
}

.free-consultation-heading {
  font-weight: 700;
  text-align: center;
  padding: 5px;
  color: #e87a28;
}

.free-consultation-contactUs-button {
  margin-top: 6px;
  padding: 10px;
  border-radius: 10px;
  background-color: #e87a28;
  color: white;
  border: none;
  transition: transform 0.11s ease-in-out;
}

.free-consultation-contactUs-button:hover {
  background-color: white;
  border: 1px solid #e87a28 !important;
  color: #e87a28;
}

.free-consultation-contactUs-button:focus {
  transform: scale(0.95);
}

.free-consultation-contactUs-button:focus:not(:active) {
  transform: scale(1);
}

@media screen and (max-width: 576px) {
  .free-consultation-main-div {
    padding: 30px 20px;
  }

  .free-consultation-text {
    border-radius: 20px;
    background-color: white;
    padding: 20px 15px 30px 15px;
  }

  .free-consultation-heading {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .free-consultation-heading {
    font-size: 18px;
  }
}
